$(() => {
  initBanners();
  initActions();
  initPhotoSlider();
  initMenu();
  initSelect();
  initStores();
  initModals();
});

const prev = '<button type="button" class="slick-prev"><svg><use xlink:href="/img/sprites/symbol/sprite.svg#prev"></use></svg></button>'
const next = '<button type="button" class="slick-next"><svg><use xlink:href="/img/sprites/symbol/sprite.svg#next"></use></svg></button>' 

const initBanners = () => {
  $('.banners').slick({
    arrows: false,
    dots: true,
    adaptiveHeight: true
  });
};

const initActions = () => {
  $('.actions').slick({
    slidesToShow: 4,
    arrows: true,
    dots: false,
    infinite: false,
    centerMode: false,
    swipeToSlide: true,
    prevArrow: prev,
    nextArrow: next,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          arrows: false,
          dots: true,
          variableWidth: true
        }
      }
    ]
  });
};

const initPhotoSlider = () => {
  $('.photo-slider').slick({
    arrows: true,
    dots: false,
    prevArrow: prev,
    nextArrow: next,
    responsive: [
      {
        breakpoint: 640,
        settings: {
          arrows: false,
          dots: true
        }
      }
    ]
  });
};

const initMenu = () => {
  $('.menu-btn').click(function(e) {
    e.preventDefault();
    $(this).toggleClass('is-active');
    $('.menu').toggleClass('is-active');
  });
};

const initSelect = () => {
  $('.select__toggle').click(function(e) {
    e.preventDefault();
    $(this).closest('.select').toggleClass('is-active');
  });

  $('.select__item').click(function(e) {
    const text = $(this).html();
    $(this)
      .closest('.select')
      .removeClass('is-active')
      .find('.select__toggle span')
      .html(text);
  });
};

const initStores = () => {
  $('.stores-categories__item button').click((e) => {
    e.preventDefault();
    e.stopPropagation();
    $('.stores-categories__item:first-child').trigger('click');
  });

  $('.stores-categories__toggle').click(function(e) {
    $(this).closest('.stores-categories').toggleClass('is-active');
  });

  $('.stores-categories__item').click(function(e) {
    const val = $(this).find('span').html();
    $('.stores-categories__toggle span').html(val);
    $(this).closest('.stores-categories').removeClass('is-active');
    $(this).addClass('is-active').siblings().removeClass('is-active');
  });
};

const initModals = () => {
  $('.js-modal-open').click(function(e) {
    e.preventDefault();
    const target = $(this).attr('data-target');
    $(target).addClass('is-active');
  });

  $('.js-modal-close').click(function(e) {
    e.preventDefault();
    $(this).closest('.modal').removeClass('is-active');
  });
};